export const cad = (amount: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    // Intentionally USD. Setting to CAD prepends the number with a `CA`.
    currency: "USD",
  }).format(amount)

export const longDate = (date: string) => {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

export const capitalize = (str: string): string => {
  str ??= ""
  const lower = str.toLowerCase()
  return str.charAt(0).toUpperCase() + lower.slice(1)
}
