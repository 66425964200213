import { Card } from "@ui/card"
import { Todos, Todo } from "@cards/todos"
import { UserType } from "@graph/types/globalTypes"

type Props = {
  type: UserType | null
  dateOfBirth: string
  businessName: string
  craBusinessNumber: string
  addressIsComplete: boolean
  bankingIsComplete: boolean
  hasJobs: boolean
}

const INCOMPLETE_DESCRIPTION = (
  <p>Welcome to HeartPayroll! Before we can start processing payroll there's a few things you need to fill out:</p>
)
const COMPLETE_DESCRIPTION = (
  <p>
    <strong>You're all set!</strong> We have all the information we need to start processing your payroll. Once the job
    begins you will be able to access your invoices and paystubs on this page.
  </p>
)

export const ForClients = ({
  type,
  dateOfBirth,
  businessName,
  addressIsComplete,
  craBusinessNumber,
  bankingIsComplete,
  hasJobs,
}: Props) => {
  const businessDetailsComplete = !!businessName && !!craBusinessNumber
  const numSteps = type === UserType.business ? 5 : 4

  const personalDetailsComplete = !!dateOfBirth
  const isComplete = personalDetailsComplete && addressIsComplete && bankingIsComplete && hasJobs
  const completedText = `${
    Number(personalDetailsComplete || 0) +
    Number(addressIsComplete || 0) +
    Number(bankingIsComplete || 0) +
    Number(hasJobs) +
    (type === UserType.business ? Number(businessDetailsComplete) : 0)
  }/${numSteps}`
  return (
    <Card
      data-testid="get-started-employer-card"
      heading={`Getting started (${completedText})`}
      description={isComplete ? COMPLETE_DESCRIPTION : INCOMPLETE_DESCRIPTION}
    >
      <Todos>
        <Todo
          id="client-get-started-personal-details"
          heading={"Personal details"}
          description={
            <>Make sure all your personal information is provided (e.g. name, contact info, date of birth).</>
          }
          href="/users/edit#personal-details"
          linkText={personalDetailsComplete ? "Edit personal details" : "Set personal details"}
          isComplete={personalDetailsComplete}
        />
        <Todo
          id="client-get-started-address"
          heading={"Address"}
          description={<>The employees pay stubs are required by law to have your adddress printed on them.</>}
          href="/users/edit#address-card"
          linkText={addressIsComplete ? "Edit address" : "Setup address info"}
          isComplete={addressIsComplete}
        />
        {type === UserType.business && (
          <Todo
            id="client-get-started-business"
            heading={"Business"}
            description={
              <>We require your business name and CRA business number to perform remittances on your behalf.</>
            }
            href="/users/edit#business-card"
            linkText={businessDetailsComplete ? "Edit business info" : "Provide business info"}
            isComplete={businessDetailsComplete}
          />
        )}
        <Todo
          id="client-get-started-banking"
          heading={"Banking"}
          description={<>To pay your employee, we need to know which bank account to pay them from.</>}
          href={"/users/edit#banking-card"}
          linkText={bankingIsComplete ? "Edit banking" : "Setup banking info"}
          isComplete={bankingIsComplete}
        />
        <Todo
          id="client-get-started-invite-employee"
          heading={"Invite an employee"}
          description="Invite your employee to join HeartPayroll and we'll take care of collecting their information."
          href={"/employees/add"}
          linkText={hasJobs ? "View employees" : "Invite an employee"}
          isComplete={hasJobs}
        />
      </Todos>
    </Card>
  )
}
