import { useState } from "react"

const PAGE_SIZE = 10

export type PagedData<T> = {
  data: T[]
  page: number
  setPage(page: number): void
  pageCount: number
}

export function usePagedData<T = any>(values: T[]): PagedData<T> {
  const [page, setPage] = useState(0)
  const start = page * PAGE_SIZE
  const end = start + PAGE_SIZE
  const data = values.slice(start, end)

  const pageCount = Math.ceil(values.length / PAGE_SIZE)
  return {
    data,
    page,
    setPage,
    pageCount,
  }
}
