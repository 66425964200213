import styled from "styled-components"
import css from "@styled-system/css"
import { variant } from "styled-system"
import { Inline } from "@ui/inline"

export type PaginationProps = {
  pageCount: number
  page: number
  setPage(page: number): void
}

export const Pagination = ({ pageCount, page, setPage }: PaginationProps) => {
  if (pageCount <= 1) {
    return null
  }
  return (
    <Inline gap="2">
      {new Array(pageCount).fill(0).map((_, index) => {
        return (
          <PagedNavigationButton
            key={index}
            onClick={() => setPage(index)}
            active={index === page}
          >
            {index + 1}
          </PagedNavigationButton>
        )
      })}
    </Inline>
  )
}

Pagination.displayName = "Pagination"

const PagedNavigationButton = styled.button<{ active: boolean }>`
  ${css({
    borderRadius: "small",
    cursor: "pointer",
    paddingY: 2,
    paddingX: 3,
    border: "none",
    bg: "transparent",
    ":hover": {
      bg: "gray.50",
    },
  })}
  ${variant({
    prop: "active",
    variants: {
      true: {
        cursor: "not-allowed",
        bg: "gray.100",
        ":hover": {
          bg: "gray.100",
        },
      },
    },
  })}
`
