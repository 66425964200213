import { CircleIcon } from "@icons/circle"
import { CircleCheckIcon } from "@icons/circle-check"
import css from "@styled-system/css"
import Box from "@ui/box"
import Button from "@ui/button"
import { Card } from "@ui/card"
import { Heading } from "@ui/heading"
import Stack from "@ui/stack"
import { ReactNode } from "react"
import styled from "styled-components"

export const Todos = styled.div`
  ${css({
    display: "flex",
    flexDirection: "column",
    i: {
      fontSize: 4,
    },
    "> *:first-child": {
      borderBottom: "none",
    },
    marginBottom: "-16px",
  })}
`

export type TodoProps = {
  id: string
  isComplete?: boolean
  heading: ReactNode
  description: ReactNode
  href: string
  linkText: string
}

export const Todo = ({ id, isComplete, heading, description, href, linkText }: TodoProps) => {
  return (
    <Card.FullWidthContent>
      <Stack direction="horizontal">
        <Box borderRight="1px solid" borderRightColor="gray.100" p="4">
          {isComplete ? <CircleCheckIcon /> : <CircleIcon />}
        </Box>
        <Stack gap="3" width="100%" padding="4">
          <Heading as="h3" level="4">
            {heading}
          </Heading>
          <p>{description}</p>
          <Button id={id} size="small" variant={isComplete ? "secondary" : "primary"} href={href} fluid={[true, false]}>
            {linkText}
          </Button>
        </Stack>
      </Stack>
    </Card.FullWidthContent>
  )
}
