import { Card } from "@ui/card"
import { usePagedData } from "use-paged-data"
import { Pagination } from "@ui/pagination"
import { trackButtonClick } from "analytics"

export type InvoicesCardProps = {
  invoices: { payDate: string; downloadUrl: string }[]
}

export const InvoicesCard = (props: InvoicesCardProps) => {
  const invoices = usePagedData(props.invoices)

  return (
    <Card heading="Invoices">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          {!invoices.pageCount && (
            <tr>
              <td className="empty-state" colSpan={2}>
                No invoices have been generated for your account.
              </td>
            </tr>
          )}
          {invoices.data.map((invoice) => (
            <tr key={invoice.payDate}>
              <td>{invoice.payDate} </td>
              <td>
                <a
                  href={invoice.downloadUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => trackButtonClick("invoice-download")}
                >
                  invoice.pdf
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination {...invoices} />
    </Card>
  )
}
