import { TopbarLayout } from "@layouts/topbar"
import { WhatWeOffer } from "@marketing/what-we-offer"
import { GetStartedForFree } from "@marketing/get-started-for-free"
import { Hero } from "@marketing/hero"
import { SignupSection } from "@marketing/signup-section"
import { Pricing } from "@marketing/pricing"
import { Testimonials } from "@marketing/testimonials"

export default function PayrollForNannies({ title }: { title: string }) {
  return (
    <TopbarLayout
      title={title ?? "Nanny pay"}
      description="Forget about nanny pay. Let our experts team handle the details of paying your nanny. Join thousands of families who are taking back their time."
      pt="0px"
      gap="0px"
    >
      <Hero heading="Nanny pay" keyword="nanny" />

      <WhatWeOffer keyword="nanny" aKeyword="a nanny" />

      <GetStartedForFree />

      <Pricing />

      <Testimonials />

      <SignupSection keywords="nannies" />
    </TopbarLayout>
  )
}
