import { UserType } from "@graph/types/globalTypes"
import { CurrentUser } from "react-current-user"
import { ForEmployees } from "./for-employees"
import { ForClients } from "./for-clients"

export type OnboardingChecklistCardProps = {
  user:
    | (CurrentUser & {
        jobsCount: number
        address: { isComplete: boolean; province: string } | null
        banking: { isComplete: boolean } | null
        tax: { isComplete: boolean } | null
        client: { businessName: string; craBusinessNumber: string } | null
      })
    | null
}

export const OnboardingChecklistCard = ({ user }: OnboardingChecklistCardProps) => {
  if (!user) {
    return null
  }

  const hasJobs = user.jobsCount > 0
  const isEmployee = user.type === UserType.employee

  if (isEmployee) {
    return (
      <ForEmployees
        dateOfBirth={user.dateOfBirth}
        addressIsComplete={user.address?.isComplete}
        bankingIsComplete={user.banking?.isComplete}
        taxIsComplete={user.tax?.isComplete}
      />
    )
  }

  if (user.client) {
    return (
      <ForClients
        type={user.type}
        businessName={user.client.businessName}
        craBusinessNumber={user.client.craBusinessNumber}
        dateOfBirth={user.dateOfBirth}
        addressIsComplete={!!user.address?.isComplete}
        bankingIsComplete={!!user.banking?.isComplete}
        hasJobs={hasJobs}
      />
    )
  }

  return null
}
