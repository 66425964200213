/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthState {
  logged_in = "logged_in",
  logged_out = "logged_out",
}

export enum BillingFrequency {
  monthly = "monthly",
  yearly = "yearly",
}

export enum DentalCoverage {
  employee_and_children = "employee_and_children",
  employee_and_family = "employee_and_family",
  employee_and_spouse = "employee_and_spouse",
  employee_only = "employee_only",
  none = "none",
}

export enum EmploymentStatus {
  active = "active",
  inactive = "inactive",
  onboarding = "onboarding",
}

export enum HoursFrequency {
  hours_frequency_bi_weekly = "hours_frequency_bi_weekly",
  hours_frequency_monthly = "hours_frequency_monthly",
  hours_frequency_semi_monthly = "hours_frequency_semi_monthly",
  hours_frequency_weekly = "hours_frequency_weekly",
}

export enum LagOption {
  current = "current",
  lag = "lag",
}

export enum LivingStatus {
  live_in = "live_in",
  live_out = "live_out",
  living_status_unknown = "living_status_unknown",
}

export enum NetOrGross {
  gross = "gross",
  net = "net",
  out_of_pocket = "out_of_pocket",
}

export enum PayCycle {
  bi_weekly = "bi_weekly",
  bi_weekly_friday_1 = "bi_weekly_friday_1",
  bi_weekly_friday_2 = "bi_weekly_friday_2",
  bi_weekly_monday_1 = "bi_weekly_monday_1",
  bi_weekly_monday_2 = "bi_weekly_monday_2",
  bi_weekly_thursday_1 = "bi_weekly_thursday_1",
  bi_weekly_thursday_2 = "bi_weekly_thursday_2",
  bi_weekly_tuesday_1 = "bi_weekly_tuesday_1",
  bi_weekly_tuesday_2 = "bi_weekly_tuesday_2",
  bi_weekly_wednesday_1 = "bi_weekly_wednesday_1",
  bi_weekly_wednesday_2 = "bi_weekly_wednesday_2",
  monthly = "monthly",
  monthly_1 = "monthly_1",
  monthly_10 = "monthly_10",
  monthly_15 = "monthly_15",
  monthly_20 = "monthly_20",
  monthly_28 = "monthly_28",
  monthly_30 = "monthly_30",
  monthly_5 = "monthly_5",
  monthly_7 = "monthly_7",
  pay_cycle_unknown = "pay_cycle_unknown",
  semi_monthly = "semi_monthly",
  semi_monthly_1 = "semi_monthly_1",
  semi_monthly_2 = "semi_monthly_2",
  weekly = "weekly",
  weekly_friday = "weekly_friday",
  weekly_monday = "weekly_monday",
  weekly_thursday = "weekly_thursday",
  weekly_tuesday = "weekly_tuesday",
  weekly_wednesday = "weekly_wednesday",
}

export enum RateType {
  annual_salary = "annual_salary",
  bi_weekly_salary = "bi_weekly_salary",
  hourly = "hourly",
  monthly_salary = "monthly_salary",
  salary = "salary",
  semi_monthly_salary = "semi_monthly_salary",
  weekly_salary = "weekly_salary",
}

export enum SalaryType {
  hourly = "hourly",
  salary = "salary",
}

export enum UserType {
  business = "business",
  client = "client",
  domestic_employer = "domestic_employer",
  employee = "employee",
}

export enum VacationPayPolicy {
  accrue = "accrue",
  payout = "payout",
}

export enum WsibManagement {
  not_required = "not_required",
  required = "required",
  unsure = "unsure",
}

/**
 * Autogenerated input type of AddEmployeeToCurrentClient
 */
export interface AddEmployeeToCurrentClientInput {
  email: string;
  firstName: string;
  lastName: string;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of RegisterPayrollForCurrentClient
 */
export interface RegisterPayrollForCurrentClientInput {
  clientEmployeeId: string;
  firstDateWorked?: string | null;
  firstPayDate?: string | null;
  regularHours: number;
  payCycle: PayCycle;
  payRate: number;
  salaryType?: SalaryType | null;
  rateType?: RateType | null;
  netOrGross: NetOrGross;
  lagOption: LagOption;
  vacationPay: VacationPayPolicy;
  livingStatus?: LivingStatus | null;
  wsibManagement: WsibManagement;
  noteFromClient?: string | null;
  billingFrequency?: BillingFrequency | null;
  couponCode?: string | null;
  dentalCoverage?: DentalCoverage | null;
  nannyShareFamilyName?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateCurrentClient
 */
export interface UpdateCurrentClientInput {
  businessName?: string | null;
  craBusinessNumber?: string | null;
  secondaryFirstName?: string | null;
  secondaryLastName?: string | null;
  secondaryPhoneNumber?: string | null;
  secondaryEmail?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateCurrentUserAddress
 */
export interface UpdateCurrentUserAddressInput {
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  province?: string | null;
  postalCode?: string | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateCurrentUserBanking
 */
export interface UpdateCurrentUserBankingInput {
  accountNumber?: string | null;
  bankName?: string | null;
  institutionNumber?: string | null;
  transitNumber?: string | null;
  acceptPadAgreement?: boolean | null;
  clientMutationId?: string | null;
}

/**
 * Autogenerated input type of UpdateCurrentUser
 */
export interface UpdateCurrentUserInput {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  dateOfBirth?: string | null;
  sin?: string | null;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
