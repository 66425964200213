import Box, { BoxProps } from "@ui/box"
import styled from "styled-components"
import { ResponsiveValue, system } from "styled-system"

export type InlineProps = BoxProps & {
  gap?: ResponsiveValue<string | number>
  align?: ResponsiveValue<"left" | "center" | "right" | "space-between">
}

export const Inline = styled(Box)<InlineProps>`
  display: flex;
  flex-wrap: wrap;
  ${system({
    gap: {
      property: "gap",
      scale: "space",
    },
    align: {
      property: "justifyContent",
      defaultScale: {
        left: "flex-start",
        center: "center",
        right: "flex-end",
      },
    },
  })}
`

Inline.displayName = "Inline"
