import { Card } from "@ui/card"
import { usePagedData } from "use-paged-data"
import { Pagination } from "@ui/pagination"
import { trackButtonClick } from "analytics"

export type PaystubsCardProps = {
  paystubs: { payDate: string; downloadUrl: string }[]
}

export const PaystubsCard = (props: PaystubsCardProps) => {
  const paystubs = usePagedData(props.paystubs)

  return (
    <Card heading="Paystubs">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          {!paystubs.pageCount && (
            <tr>
              <td className="empty-state" colSpan={2}>
                No paystubs have been generated for your account.
              </td>
            </tr>
          )}
          {paystubs.data.map((paystub) => (
            <tr key={paystub.payDate}>
              <td>{paystub.payDate} </td>
              <td>
                <a
                  href={paystub.downloadUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => trackButtonClick("paystub-download")}
                >
                  paystub.pdf
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination {...paystubs} />
    </Card>
  )
}
