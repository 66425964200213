import { Card } from "@ui/card"
import { Todos, Todo } from "@cards/todos"

type Props = {
  dateOfBirth?: string
  addressIsComplete?: boolean
  bankingIsComplete?: boolean
  taxIsComplete?: boolean
}

const INCOMPLETE_DESCRIPTION = (
  <p>Congratulations on your new job! Before we can start paying you, there's a few things you need to fill out:"</p>
)
const COMPLETE_DESCRIPTION = (
  <p>
    <strong>You're all set!</strong> We have all the information we need to start processing your payroll. Once your new
    job begins you will be able to access your paystubs on this page.
  </p>
)

export const ForEmployees = ({ dateOfBirth, addressIsComplete, bankingIsComplete, taxIsComplete }: Props) => {
  const personalDetailsComplete = !!dateOfBirth
  const isComplete = personalDetailsComplete && addressIsComplete && bankingIsComplete && taxIsComplete
  const completedText = `${
    Number(personalDetailsComplete || 0) +
    Number(addressIsComplete || 0) +
    Number(bankingIsComplete || 0) +
    Number(taxIsComplete || 0)
  }/4`

  return (
    <Card
      data-testid="get-started-employee-card"
      heading={`Getting started (${completedText})`}
      description={isComplete ? COMPLETE_DESCRIPTION : INCOMPLETE_DESCRIPTION}
    >
      <Todos>
        <Todo
          id="employee-get-started-personal-details"
          heading={"Personal details"}
          description={
            <>Make sure all your personal information is provided (e.g. name, contact info, date of birth).</>
          }
          href="/users/edit#personal-details"
          linkText={personalDetailsComplete ? "Edit personal details" : "Set personal details"}
          isComplete={personalDetailsComplete}
        />
        <Todo
          id="employee-get-started-address"
          heading={"Address"}
          description={<>Pay stubs are required by law to have your adddress printed on them.</>}
          href="/users/edit#address-card"
          linkText={addressIsComplete ? "Edit address" : "Setup address info"}
          isComplete={addressIsComplete}
        />
        <Todo
          id="employee-get-started-banking"
          heading={"Banking"}
          description={<>To send you money, we need your banking details.</>}
          href={"/users/edit#banking-card"}
          linkText={bankingIsComplete ? "Edit banking" : "Setup banking info"}
          isComplete={bankingIsComplete}
        />
        <Todo
          id="employee-get-started-tax"
          heading={"Provide your tax information"}
          description={
            <>
              The government requires we report your income, tax deductions, and the amount withheld for government
              benefit programs and services.
            </>
          }
          href={"/users/edit#tax-card"}
          linkText={taxIsComplete ? "Edit tax info" : "Setup tax info"}
          isComplete={taxIsComplete}
        />
      </Todos>
    </Card>
  )
}
