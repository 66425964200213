import { Heading } from "@ui/heading"
import Stack from "@ui/stack"
import { longDate } from "format"
import { useEffect, useState } from "react"

type NextStatHolidayAsideProps = {
  provinceId: string
}

export const NextStatHolidayAside = ({ provinceId }: NextStatHolidayAsideProps) => {
  if (provinceId === "PEI") {
    provinceId = "PE"
  }
  const [province, setProvince] = useState<Province | undefined>()

  useEffect(() => {
    fetch("https://canada-holidays.ca/api/v1/provinces")
      .then((response) => {
        return response.json()
      })
      .then((data: CanadaHolidayProvincesData) => {
        setProvince(data.provinces.find((province) => province.id === provinceId))
      })
      .catch(() => {
        // Ignore: Non-critical interface.
      })
  }, [provinceId])
  const nextHoliday = province?.nextHoliday

  if (!nextHoliday) return null
  return (
    <Stack
      as="section"
      role="note"
      p="4"
      borderRadius="medium"
      border="1px solid"
      borderColor="gray.200"
      bg="transparent"
      gap="4"
      direction="horizontal"
      alignItems="center"
    >
      <Heading as="div" level="1">
        <i className="fa-regular fa-calendar-days"></i>
      </Heading>
      <Stack gap="2">
        <Heading as="h2" level="4">
          Next stat holiday in {province.nameEn}:
        </Heading>
        <Heading as="div" level="2">
          {nextHoliday.nameEn}, {longDate(nextHoliday.date)}
        </Heading>
      </Stack>
    </Stack>
  )
}

type CanadaHolidayProvincesData = {
  provinces: Province[]
}

type Province = {
  id: string
  nameEn: string
  nextHoliday: Holiday
}

type Holiday = {
  id: string
  date: string
  federal: number
  nameEn: string
}
