import { SidebarLayout } from "@layouts/sidebar"
import { GetServerSideProps } from "next"
import { createServerClient } from "@graph/client"
import { DashboardPageQuery } from "@graph/types/DashboardPageQuery"
import DASHBOARD_QUERY from "@graph/queries/page-dashboard.gql"
import { InvoicesCard, InvoicesCardProps } from "@cards/invoices"
import { PaystubsCard, PaystubsCardProps } from "@cards/paystubs"
import { userAccountTypePath } from "@app/routes"
import { CurrentUser } from "react-current-user"
import { ServerResponse } from "@server/response"
import { PageProps } from "@server/props"
import Stack from "@ui/stack"
import { PageHeader } from "@ui/pages"
import { NextStatHolidayAside } from "@asides/next-stat-holiday"
import LandingPage from "./nanny-pay"
import { isClient } from "is-client"
import { OnboardingChecklistCard as OnboardingChecklist } from "@cards/onboarding-checklist"

type DashboardPageProps = PageProps<Omit<LoggedInIndexProps, "user"> & { user?: LoggedInIndexProps["user"] | null }>

export default function DashboardPage({ user, invoices, paystubs }: DashboardPageProps) {
  if (!user) {
    return <LandingPage title="" />
  }

  return <LoggedInIndex user={user} invoices={invoices} paystubs={paystubs} />
}

type LoggedInIndexProps = {
  user: CurrentUser & {
    jobsCount: number
    address: { isComplete: boolean; province: string } | null
    banking: { isComplete: boolean } | null
    tax: { isComplete: boolean } | null
    client: { businessName: string; craBusinessNumber: string } | null
  }
  invoices: InvoicesCardProps["invoices"]
  paystubs: PaystubsCardProps["paystubs"]
}

const LoggedInIndex = ({ user, paystubs, invoices }: LoggedInIndexProps) => {
  const hasHadActivePayroll = paystubs.length + invoices.length > 0

  return (
    <SidebarLayout title="Dashboard">
      <Stack gap="5">
        <PageHeader heading="Dashboard" />

        {!hasHadActivePayroll && <OnboardingChecklist user={user} />}

        {!!paystubs.length && user.address?.province && <NextStatHolidayAside provinceId={user.address.province} />}

        {isClient(user) && <InvoicesCard invoices={invoices} />}

        <PaystubsCard paystubs={paystubs} />
      </Stack>
    </SidebarLayout>
  )
}

export const getServerSideProps: GetServerSideProps<DashboardPageProps> = async (ctx) => {
  const client = createServerClient({ headers: ctx.req.headers })

  const { data } = await client.query<DashboardPageQuery>({
    query: DASHBOARD_QUERY,
  })

  const { admin, user, invoices, paystubs } = data

  if (user && user.isConfirmed && !user.type) {
    return ServerResponse.redirect(userAccountTypePath)
  }

  return {
    props: { admin, user, invoices, paystubs },
  }
}
